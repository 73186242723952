// extracted by mini-css-extract-plugin
export var alignLeft = "q_p6 d_fl d_bC d_dr";
export var alignCenter = "q_bL d_fm d_bz d_ds";
export var alignRight = "q_p9 d_fn d_bD d_dt";
export var menuContainer = "q_qX d_dS";
export var menuContainerFull = "q_qY d_dQ";
export var menuMainHeader = "q_jz d_jz d_s d_c0";
export var menuComponentWrapper = "q_jD d_jD d_cr";
export var menuComponentWrapperDesign2 = "q_jF d_jF d_cv";
export var menuComponentText = "q_jB d_jB d_ck";
export var menuComponentTextDesign2 = "q_jC d_jC d_cp";
export var menuImageWrapperDesign2 = "q_jG d_jG d_s d_W";