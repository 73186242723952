// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "r_qZ d_bz";
export var storyRowWrapper = "r_hv d_hv d_bG";
export var storyLeftWrapper = "r_q0 d_bw d_bL";
export var storyWrapperFull = "r_q1 d_cz";
export var storyWrapperFullLeft = "r_nQ d_cz d_bw d_bL d_bz";
export var contentWrapper = "r_mC d_hw";
export var storyLeftWrapperCenter = "r_q2 d_hx d_bw d_bz d_bL d_bF";
export var storyRightWrapperCenter = "r_q3 d_hC";
export var storyHeader = "r_q4 d_hB d_s d_cp";
export var storyHeaderCenter = "r_hz d_hz d_s d_cp d_ds d_bv";
export var storyParagraphCenter = "r_hy d_hy d_bv d_ds";
export var storyBtnWrapper = "r_q5 d_dZ d_dY d_s d_bw d_bC";
export var storyBtnWrapperCenter = "r_hG d_hG d_dY d_s d_bw d_bz";
export var imageWrapper = "r_q6 d_fc d_W";
export var imageWrapperFull = "r_q7 d_s d_D d_bb d_W";