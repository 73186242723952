// extracted by mini-css-extract-plugin
export var lbContainerOuter = "v_sx";
export var lbContainerInner = "v_sy";
export var movingForwards = "v_sz";
export var movingForwardsOther = "v_sB";
export var movingBackwards = "v_sC";
export var movingBackwardsOther = "v_sD";
export var lbImage = "v_sF";
export var lbOtherImage = "v_sG";
export var prevButton = "v_sH";
export var nextButton = "v_sJ";
export var closing = "v_sK";
export var appear = "v_sL";