// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "p_qD d_gs d_cp";
export var heroHeaderCenter = "p_gt d_gt d_cp d_ds";
export var heroHeaderRight = "p_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "p_qF d_gp d_cs";
export var heroParagraphCenter = "p_gq d_gq d_cs d_ds";
export var heroParagraphRight = "p_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "p_qG d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "p_qH d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "p_qJ d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "p_qK d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "p_qL d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "p_qM s_qM";
export var heroExceptionNormal = "p_qN s_qN";
export var heroExceptionLarge = "p_qP s_qP";
export var Title1Small = "p_qQ s_qQ s_q8 s_q9";
export var Title1Normal = "p_qR s_qR s_q8 s_rb";
export var Title1Large = "p_qS s_qS s_q8 s_rc";
export var BodySmall = "p_qT s_qT s_q8 s_rs";
export var BodyNormal = "p_qV s_qV s_q8 s_rt";
export var BodyLarge = "p_qW s_qW s_q8 s_rv";